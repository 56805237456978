import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";

const NotFoundPage = () => (
  <Layout nav={true}>
    <SEO title="404: Not found" />
    <section style={{height: "70vh"}} className="uk-section">
        <div style={{height: "100%"}} className="uk-container uk-flex uk-flex-center uk-flex-middle uk-flex-column uk-text-center">
            <h1>404<br /><span className="uk-h2 uk-margin-remove">NOT FOUND</span></h1>
        </div>
    </section>
  </Layout>
)

export default NotFoundPage;
